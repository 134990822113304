
$(document).ajaxError(function (event, jqxhr, settings, thrownError) {
   if (jqxhr.status === 401) {
        document.location.href = '/';
   }
});


window.initComponents = function() {

    $('[data-toggle="popover"]').popover({
            "trigger": "hover",
            "container": "body",
            "placement": "left",
            "html": true
    });

    $('[data-toggle="tooltip"]').tooltip({
        "trigger": "hover",
        "placement": "top",
        "delay": { show: 400, hide: 200 },
        "html": true,
        "container": 'body'
        });

    $("[id^='tooltip']").tooltip('hide'); 
}

$(document).ready(function(){

    $('.counter').each(function () {
        var $this = $(this);
        jQuery({ Counter: 0 }).animate({ Counter: $this.text() }, {
            duration: 1000,
            easing: 'swing',
            step: function () {
                $this.text(Math.ceil(this.Counter));
            }
        });
    });

});


window.dt_options = {

     initComplete: function(settings, data) {
        //console.log("initComplete");
        window.initComponents();
    },

    drawComplete: function(settings, data) {
        window.initComponents();
        //console.log("drawComplete");
    }

}



/*
window.initComponents = function() {
    $('[data-toggle="popover"]').popover({
            "trigger": "hover",
            "container": "body",
            "placement": "left",
            "html": true
    });

    $('[data-toggle="tooltip"]').tooltip({
        "trigger": "hover",
        "placement": "top",
        "delay": { show: 400, hide: 200 },
        "html": true,
        "container": 'body'
        });

    $("[id^='tooltip']").tooltip('hide'); 
}


var dt_options = {

    "initComplete" : function(settings, data){
        initComponents();
    },

    "drawComplete" : function(settings, data){
        initComponents();
    } 
}
*/
