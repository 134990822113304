window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
//    window.$ = window.jQuery = require('jquery');

    //require('bootstrap');

    //require( 'datatables.net-dt' );
    //require( 'datatables.net-bs4' );
    //require( 'datatables.net-buttons-bs4' );
    //import 'datatables.net-buttons-bs4';
    //require( 'datatables.net-buttons/js/buttons.html5.js' );
    //require( 'datatables.net-responsive' );
    //require( 'datatables.net-responsive-bs4' );
    //require( 'datatables.net-scroller-bs4'  );

} catch (e) {

    console.log("Error", e.stack);
    console.log("Error", e.name);
    console.log("Error", e.message);
}

//import $ from 'jquery';
import jquery from 'jquery';
window.jQuery = jquery;
window.$ = jquery;

// bootstrap
//require('bootstrap')

require('bootstrap');
//import jsZip from 'jszip';
//window.JSZip = jsZip;

import * as JSZip from "jszip";
window.JSZip = JSZip;
 
import 'datatables.net';
//import 'datatables.net-dt';

import 'datatables.net-bs4';
import 'datatables.net-buttons';
import 'datatables.net-buttons-bs4';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4';
import 'datatables.net-scroller-bs4';

//import * as Bootstrap from 'bootstrap';

//window.Bootstrap = Bootstrap;

//require('popper.js');

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

//import jsZip from 'jszip';
//window.JSZip = jsZip;

/*
require( 'datatables.net-dt' );
require( 'datatables.net-bs4' );
require( 'datatables.net-buttons-bs4' );
require( 'datatables.net-buttons/js/buttons.html5.js' );
require( 'datatables.net-responsive' );
require( 'datatables.net-responsive-bs4' );
require( 'datatables.net-scroller-bs4'  );
*/
require( 'select2' );

require( 'bootstrap-datepicker' );

require( 'admin-lte' );


window.Swal = require( 'sweetalert2' );

window.SwalToast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000
            });


window.FilePond = require('filepond');

// Import the plugin code
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

// Register the plugins
window.FilePond.registerPlugin(FilePondPluginFileValidateType);
window.FilePond.registerPlugin(FilePondPluginImagePreview);


/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
